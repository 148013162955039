<template>
  <!-- <div class="msg animate__animated animate__fadeInRight"> -->
  <div class="msg ">
    <div class="msg-text ">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  name: 'question',
  components: {},
  props: {
    msg: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.msg {
  margin: 10px 0;

  color: #646466;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
//   padding-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .msg-text {
    // padding-left: 40px;
    // display: inline-block;
    display: inline-block;

    background-color: #fff;
    // border-radius: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 5px 10px 10px;
    margin-right: 5px;
    margin-left: 35px;
    line-height: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    // background: linear-gradient(270deg, rgba(255,174,1,60%) 0%, rgba(255,174,1,10%) 100%);
    background-color: #FCCB66;

  }
}
</style>
